import React, { useState, useEffect } from 'react'
import { render } from 'react-dom'
import { useTrail, animated } from 'react-spring'
import './FadeInText.css'

const items = ['Appletree']
const config = { mass: 5, tension: 2000, friction: 200 };

export default function FadeInText() {
  const [toggle, set] = useState(true)
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if(window.scrollY < 100) set(true)
      else set(false)
    });
  }, [])
  return (
    <div className="trails-main">
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <animated.div
            key={items[index]}
            className="trails-text"
            style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>
            <animated.div style={{ height }}>{items[index]}</animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  )
}

